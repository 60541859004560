import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function ResidenceUser({ id, firstName, lastName }) {
  const navigate = useNavigate();
  return (
    <Col className="residence-user">
      <Row className="align-items-center">
        <Col lg={6}>
          {firstName} {lastName}
        </Col>
        <Col lg={6} className="right">
          <Button
            size="sm"
            onClick={() => navigate(`/edit-user/${id}`)}
            variant="info"
            style={{ marginRight: 10 }}
          >
            Editar
          </Button>
          <Button
            size="sm"
            onClick={() => navigate(`/edit/cardCode/${id}`)}
            variant="warning"
            style={{ marginRight: 10 }}
          >
            Tarjeta vehicular
          </Button>
          <Button
            size="sm"
            onClick={() => navigate(`/edit/keyChain/code/${id}`)}
            variant="primary"
            style={{ marginRight: 10 }}
          >
            Tarjeta personal
          </Button>
          <Button
            size="sm"
            onClick={() => navigate(`/delete-user/${id}`)}
            variant="danger"
          >
            Eliminar
          </Button>
        </Col>
      </Row>
    </Col>
  );
}

export default ResidenceUser;
